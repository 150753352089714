import "./forms.scss";
import * as dompack from 'dompack';
import * as forms from "@mod-publisher/js/forms";
import { dashPrice } from "../../lifestyleshop.es";

//Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
// import '@mod-publisher/js/forms/themes/neutral';

//Optionally: replaces upload fields with a nicer and edit-supporting version
import UploadField from '@mod-publisher/js/forms/fields/upload';
dompack.register(".wh-form__upload", node => new UploadField(node));

//Enable the imgedit and/or rtd fields:
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));

import RTDField from '@mod-publisher/js/forms/fields/rtd';
dompack.register(".wh-form__rtd", node => new RTDField(node));


forms.setup({ validate: true });

let date = dompack.qS('[type=date]');


function noMondays(e)
{
  let day = new Date( e.target.value ).getUTCDay();
  let today = new Date().toISOString().split('T')[0];
  let d = new Date(e.target.value);

  const formatchosendate = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear();
  let disabledates = ["25-12-2021", "26-12-2021"]

  if ( day == 1 ) {

      if (formatchosendate == '5-4-2021')
      {
        e.target.setCustomValidity('');
      } else {

        if (document.getElementsByTagName("html")[0].classList.contains('page--isshopnl')) {
          e.target.setCustomValidity('Op maandag zijn wij gesloten, kies aub een andere dag.');
        } else {
          e.target.setCustomValidity('Wir sind montags geschlossen, bitte wählen Sie einen anderen Tag.');
        }

      }

  } else if (disabledates.includes(formatchosendate)) {

      if (document.getElementsByTagName("html")[0].classList.contains('page--isshopnl')) {
        e.target.setCustomValidity('Op deze dag zijn wij gesloten, kies aub een andere dag.');
      } else {
        e.target.setCustomValidity('Wir sind an diesem Tag geschlossen, bitte wählen Sie einen anderen Tag.');
      }

  } else {
    e.target.setCustomValidity('');
  }
}

if (typeof (date) != 'undefined' && date !== null)
{
  date.min = new Date().toISOString().split("T")[0];
  date.addEventListener('input',noMondays);
}

forms.registerMergeFormatter("dashPrice", dashPrice);
