// Required libraries (all shops)
import '@mod-wrd/js/auth'; //Login and authentication
import '@mod-publisher/js/richcontent/all'; //RTD support
import * as defaultshop from '@mod-webshop/js/composer/defaultshop'; //A shop
import Product from '@mod-webshop/js/shopservice/product';
import * as dialogapi from 'dompack/api/dialog';
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

// HP Styling/design. This is removable
import 'magnific-popup/dist/magnific-popup.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './shared/custom';
import 'line-awesome/dist/line-awesome/scss/line-awesome.scss';


// import "./shared/cookies.es";
import * as dialog from 'dompack/components/dialog';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';

import './lifestyleshop.scss';
import './rtd';
import './header';
import './pages';
import './footer';
import './widgets';
import './shared/forms';
import './components/select/';

import '@mod-forshops/shopextensions/cookiebar/frontend/cookiebar.es';

import * as finmath from '@mod-system/js/util/finmath';

export function dashPrice(inprice)
{
  if(inprice.substr(-3) == ',00')
    return inprice.substr(0, inprice.length-3) + ",-";
  return inprice;
}


function refreshCartStatus(webshop)
{
  var getcartitems = webshop.getCart().numarticles;
  if(getcartitems == 0) //verlanglijstje leeg!
  {
    dompack.qSA('.checkoutlink').forEach(node => node.style.display = "none");
  }

  dompack.qSA('.hp__numincart').forEach(node => node.textContent = webshop.getCart().numarticles);
}

addEventListener('webshop:cartupdated', function(evt)
{
  refreshCartStatus(evt.detail.webshop);
});
addEventListener('webshop:productadded', function(evt)
{
  if (!evt.defaultPrevented)
  {
    evt.preventDefault();
    window.setTimeout(() => evt.detail.webshop.gotoCheckout(), 100); //give GTM time to pick up the product add  (TODO fix this in the main code. work with GTA to get supporting browsers into BEACON mode)
  }
});

//the dialog implementation can differ, but -some- form of dialog callback needs to be registered using dialogapi.setupDialogs
dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

function shopReady(webshop)
{
  dompack.register('.productform', node =>
  {
    new Product(webshop, node);
  });

  refreshCartStatus(webshop);
  dompack.dispatchCustomEvent(window, "schippers:shopready", { bubbles:false, cancelable:false, detail: { webshop } });
}

function onNewCartDom(dom)
{
  dompack.qSA(dom,".webshop-listcart__delete").forEach(node => node.appendChild(<span class="la la-trash" style="font-size:18px;"></span>));
}

//configures the shop and registers handlers
defaultshop.setup(
    { onReady: shopReady
    , onNewCartDom
    });

//verlanglijstje - verwijder blokje bij klikken
dompack.register("html.ls--verlanglijstje .ls__removefromlist", node => node.addEventListener("click", event =>
{
  let holder = dompack.closest(event.target, '.productsgrid__product');
  holder.addEventListener("transitionend", () =>
    {
      holder.parentNode.removeChild(holder);
      if(dompack.qSA(".productsgrid__product").length == 0) //verlanglijstje leeg!
        location.reload(); //will give feedback about empty list
    });
  holder.classList.add("productsgrid__product--fadeout");
}));


dompack.register('.hp-checkout__addtrigger', node => node.addEventListener('click', evt =>
{
  dompack.stop(evt);
  dompack.qSA('.hp-checkout__addcode').forEach(node => node.style.height = node.scrollHeight + 'px');
}));

function mobileProductFilterPlaceholder()
{
  //on mobile, move product filter block to placeholder
  if(!window.matchMedia("(max-width:992px)").matches)
    return;

  let insertionpoint = dompack.qS('.mobileproductfilter_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('.widget-productfilter');
  if(!moveto)
    return;
    console.log(moveto);

  insertionpoint.appendChild(moveto);

}

dompack.onDomReady(mobileProductFilterPlaceholder);


function fixupMobileOrderTop()
{
  //on mobile, move any item marked as mobile-order-top up
  if(!window.matchMedia("(max-width:768px)").matches)
    return;
  
  let insertionpoint = dompack.qS('.hp__mobileitemsplaceholder');
  if(!insertionpoint)
    return;

  let moveup = dompack.qSA('main > .mobile-order-top');
  if(moveup.length == 0)
    return;

  moveup.forEach(tomoveup =>
  {
    if(tomoveup.previousSibling && tomoveup.previousSibling.nodeName.match(/^H[123456]$/)) //allow the previous heading to move along with us!
      insertionpoint.parentNode.insertBefore(tomoveup.previousSibling, insertionpoint);
    insertionpoint.parentNode.insertBefore(tomoveup, insertionpoint);
  })

}

dompack.onDomReady(fixupMobileOrderTop);

openLinksInNewWindow( { extensions : ["pdf"] });

function startNotificationRequest()
{
  let duration = 500;//ms
  let showStatusStorage = localStorage;
  let notificationmsg = dompack.qS('#custom-notification');

  if (!showStatusStorage.getItem("showStatus")) {
    showStatusStorage.setItem("showStatus", "show");
    notificationmsg.classList.remove("d-none");
  } else {
    getWithExpiry("showStatus")

    if (showStatusStorage.getItem("showStatus") === "show") {
      notificationmsg.classList.remove("d-none");
    }
  }

  notificationmsg.style.transition = duration + "ms height";

  document.body.prepend(notificationmsg);
  notificationmsg.clientWidth;//force css update

  //Show cookie message
  notificationmsg.style.height = notificationmsg.children[0].clientHeight + "px";
  let animtimer = setTimeout(function(){
    notificationmsg.style.height = "auto";//so if resizing window content always fits
  }, duration);

  for( let btnnode of notificationmsg.querySelectorAll("button") )
  {
    btnnode.addEventListener("click", ev => {

      if( dompack.closest(btnnode, ".closemsg")) {
        setWithExpiry("showStatus", "dontShow", 86400) //86400 = 1 day
        notificationmsg.classList.add("d-none");
      } else {
        showStatusStorage.setItem("showStatus", "show");
      }

      clearTimeout(animtimer);

      notificationmsg.style.height = notificationmsg.clientHeight + "px";
      notificationmsg.clientWidth;//force css update
      notificationmsg.style.height = "";//Hide

      animtimer = setTimeout(function(){
        notificationmsg.removeChild(notificationmsg);
      }, duration);

      location.reload();

    });
  }
}
dompack.onDomReady(startNotificationRequest);


function setWithExpiry(key, value, ttl) 
{
  const now = new Date()
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) 
{
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return null
  }

  const item = itemStr
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}