import "./categorypage.scss";
import "./productpage.scss";
import * as dompack from 'dompack';
import * as domevents from "dompack/src/events";
import * as merge from 'dompack/extra/merge';

import $ from "jquery";

$( document ).ready(function()
{
    // init magnific popup in productpage
    var form = document.getElementById("product__images__preview__container");
    var have_images = document.querySelectorAll('.product__images__grid, .product__images__preview').length !== 0;

    if (typeof (form) != 'undefined' && form !== null && have_images)
    {
        $('.product__images__grid, .product__images__preview').magnificPopup(
        {
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery:
            {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image:
            {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: item => item.el.find('img').attr('alt')
            }
        });
    }

    // init slick-carousel on product images
    $('#product__images__preview__container').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      fade: true,
      asNavFor: '.product__images__grid'
    });

    $('.product__images__grid').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      asNavFor: '#product__images__preview__container',
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      responsive: [
          {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
          {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3
          }
        }
      ]

    });

    // stickytabs productpage
    $('#product__tabs .nav-tabs').stickyTabs();

});

merge.registerUpdater("productPagePreviewImage", (node, data) =>
{
  node.dataset.initialidx = data.idx;
  if (node.classList.contains("slick-initialized"))
    $(node).slick("slickGoTo", data.idx);
});

// add active class to tabs when onclick
dompack.register('.nav-tab', clickedTab =>
{
    clickedTab.addEventListener('click', () =>
    {
        let tabs = dompack.qSA(".nav-tab");
        tabs.forEach(tab =>
        {
            tab.classList.remove('active');
        });

        clickedTab.classList.add('active');
    });
});

// if filter button exsists add click action scrollIntoView of productsgrid_anchor
dompack.register('#btn-filter', target =>
{
  if (typeof (target) != 'undefined' && target !== null)
  {
    target.onclick = function (e)
    {
      let elem = dompack.qS('#productsgrid_anchor');
      elem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
      e.preventDefault();
    }
  }
});

// if cookie wh-webshop-favlist-wishlist exsist remove class from wishlist menu item
if (document.cookie.split(';').filter((item) => item.trim().startsWith('wh-webshop-favlist-wishlist=')).length)
{

    let wishlistitems = dompack.qSA(".wishlist-item");
    wishlistitems.forEach(wishlistitem =>
    {
        wishlistitem.classList.remove('d-none');
    });
}

function createFacebookSharerUrl()
{
  let current_url  = window.location.origin + window.location.pathname;
  let insertionpoint = dompack.qS('.facebooksharer_placeholder');
  if(!insertionpoint)
    return;
  let facebookSharerUrl = <a href={"https://www.facebook.com/sharer/sharer.php?u=" + current_url} title="Deel via Facebook"><i class="la la-facebook"></i></a>;
  insertionpoint.appendChild(facebookSharerUrl);
}

dompack.onDomReady(createFacebookSharerUrl);

function createPinterestSharerUrl()
{
  let current_url  = window.location.origin + window.location.pathname;
  let insertionpoint = dompack.qS('.pinterestsharer_placeholder');
  if(!insertionpoint)
    return;
  let pinterestSharerUrl = <a href={"http://pinterest.com/pin/create/button/?url=" + current_url} title="Deel via Pinterest"><i class="la la-pinterest-p"></i></a>;
  insertionpoint.appendChild(pinterestSharerUrl);
}

dompack.onDomReady(createPinterestSharerUrl);


function mobileOptionsPlaceholder()
{
  //on mobile, move product filter block to placeholder
  if(!window.matchMedia("(max-width:767px)").matches)
    return;

  let insertionpoint = dompack.qS('.mobile_product__options_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('.product__options');
  if(!moveto)
    return;
    console.log(moveto);

  insertionpoint.appendChild(moveto);

}

dompack.onDomReady(mobileOptionsPlaceholder);
