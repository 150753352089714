import "./cart-and-checkout.scss"; //For CSS styling
import "./cart-localhacks.scss"; //For actual deviations from the standard setup, which go hand-in-hand with checkout plugin

import * as dompack from 'dompack';

dompack.register(".wh-wrdauth__loginform button", node => {
  node.classList.add("btn");
  node.classList.add("btn-green");
});

dompack.register('.hp-checkout__addtrigger', node => node.addEventListener('click', evt =>
{
  dompack.stop(evt);
  dompack.qSA('.hp-checkout__addcode').forEach(node => node.style.height = node.scrollHeight + 'px');
}));

dompack.register(".hp-checkout__repeatedcartshow", node => node.addEventListener("click", evt =>
{
  dompack.stop(evt);
  document.documentElement.classList.add("hp--showrepeatedcart");
}));

window.addEventListener("webshop:cartupdated", evt=>
{
  let webshop = evt.detail.webshop;
  let numproducts = webshop.getCart().numarticles;

  dompack.qSA(".hp-checkout__repeatedcarttext").forEach(node =>
    node.textContent = numproducts + " " + (numproducts == 1 ? "product" : "producten"));
});

//handle options open/close click. registering centrally to avoid registering on every cart/dom change
window.addEventListener("click", evt =>
{
  let toggler = dompack.closest(evt.target, ".skw__optionstoggle");
  if(toggler)
  {
    dompack.toggleClass(toggler, "skw__optionstoggle--open");
  }
});
