import $ from "jquery";
import {Tab, Button, Dropdown, Util} from 'bootstrap/js/src';
import * as popperjs from 'popper.js/dist/esm/popper.min.js';
import * as dompack from 'dompack';
import * as magnificpopup from 'magnific-popup';
import slick from 'slick-carousel';
import * as sidebarjs from 'sidebarjs';

//import './jquery.instagramFeed.js';
import './stickytabs.js';
import 'lazysizes';
import './jquery.ddslick.js';

function initOptionDDSlick(select, reinit)
{
  /* what this should do:

    reinit = false: initial init
    reinit = true: re-init

    missing ddslick stuff:
    - hidden options should be hidden in ddslick too (unless currently selected)
    - ddslick destroy doesn't seem to work
  */

  if (reinit)
  {
    // ddslick active?
    const ddinput = select.previousSibling && dompack.closest(select.previousSibling, ".dd-container");
    if (ddinput)
      $(ddinput).ddslick("destroy");
  }

  // Don't trigger for hidden options
  if (dompack.closest(select, ".webshop-product__option--hidden"))
    return;

  try
  {
    $(select).ddslick({
        width: '100%',
        onSelected : function(data)
        {
          dompack.fireModifiedEvents(select);
        }
    });
  }
  catch (e)
  {
    console.log(`error initializing ddslick`, e);
  }

  dompack.registerMissed(select.parentNode);

}

$( document ).ready(function()
{
    // Enable popovers everywhere
    $('[data-toggle="popover"]').popover({
        html: true,
        trigger: 'toggle',
        content: function() {
          // get the url for the full size img
          var url = $(this).data('image');
          return '<img src="' + url + '">';
        }
      });

    // sidemenu
    var leftSidebarjs = new sidebarjs.SidebarElement({
        component: document.querySelector('[sidebarjs="leftSidebar"]'),
        nativeSwipeOpen: false
    });

    // filter sidenav
    var filterpanel = document.querySelector('[sidebarjs="filterSideNav"]');

    if (typeof (filterpanel) != 'undefined' && filterpanel !== null)
    {

      var filterSidebarjs = new sidebarjs.SidebarElement({
          component: document.querySelector('[sidebarjs="filterSideNav"]'),
          nativeSwipeOpen: false,
          nativeSwipe: false
      });

    }

    for (const elt of document.querySelectorAll("#fakeproductoption"))
    elt.addEventListener("change", (evt) =>
    {
      console.log(`change`, evt);
      if (event.target.selectedOptions[0].dataset.redirect)
        window.location.href = event.target.selectedOptions[0].dataset.redirect;
    });


    // toggle ul in sidemenu
    $(".has-submenu > .submenu-toggle").click(function()
    {
      $(this).next('ul').toggleClass('d-block');
      $(this).toggleClass('collapse--open');
      $(this).parent().parent().find('.submenu').hide();
      $(this).parent().parent().removeClass('collapse--open');
    });

    // image gallery productpage
    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      centerMode: true,
      focusOnSelect: true,
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
    });

    // product slider widget
    $('.products-slider').slick({
      infinite: true,
      slidesToShow: 4,
      speed: 300,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 4
          }
        },
          {
          breakpoint: 992,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    });

    // product slider widget
    $('.logo-slider').slick({
      infinite: true,
      slidesToShow: 7,
      speed: 300,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            arrows: false,
            slidesToShow: 7
          }
        },
        {
          breakpoint: 1300,
          settings: {
            arrows: false,
            slidesToShow: 6
          }
        },
        {
          breakpoint: 1200,
          settings: {
            arrows: false,
            slidesToShow: 5
          }
        },
          {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 2
          }
        }
      ]
    });

    //instagram feed
    // $.instagramFeed({
    //   'username': 'schipperslifestyle',
    //   'container': '#instagram-feed1',
    //   'display_profile': true,
    //   'display_biography': true,
    //   'display_gallery': true,
    //   'callback': null,
    //   'styling': false,
    //   'items': 12,
    //   'items_per_row': 4,
    //   'margin': 0.1
    // });


    //tag widget in sidebar op tagpagina's
    $('.tagitems').each(function(i)
    {
       var $list = $(this);
       var n = $(this).children().length;
       if (n < 4)
       return;

       $list.after('<button class="btn btn-link border-0 more_less pl-0">Meer items</button>');
       $list.find('li:gt(5)').hide();
    });


    $('.more_less').click(function()
    {
        var $btn = $(this)
        $btn.prev().find('li:gt(5)').toggle();
        $btn.text($btn.text() == 'Meer items' ? 'Minder items' : 'Meer items');
    });

    // ddslick selectmenu product options
    $('.webshop-product__optionselect').each(function()
    {
      let select = this;

      initOptionDDSlick(select);
      select.addEventListener("webshop:optionschanged", evt =>
      {
        initOptionDDSlick(select, true);
      });

    });

    dompack.register("a.dd-option", enableDDOptionPreview);

});

function enableDDOptionPreview(ddoption)
{
  if (!ddoption.classList.contains("image-preview"))
  {
    const imgprev = $(ddoption);
    if (imgprev.find('img').length > 0)
    {
      imgprev.addClass('image-preview');
      imgprev.attr('rel', $(imgprev.find('img')[0]).attr('src'));

      initImagePreviewForNode(ddoption);
    }
  }
}

function initImagePreviewForNode(node)
{
    var xOffset = 10;
    var yOffset = 30;

    $(node).hover(function(e)
    {
        this.t = this.title;
        this.title = '';
        var c = (this.t !== '') ? '<br>' + this.t : '';
        $('body').append('<div id="image-preview"><img src="'+ this.rel + '" alt="Image preview">' + c + '</div>');
        $('#image-preview')
            .css('top',(e.pageY - xOffset) + 'px')
            .css('left',(e.pageX + yOffset) + 'px')
            .fadeIn('fast');
    },
    function()
    {
        this.title = this.t;
        $('#image-preview').remove();
    });

    $(node).mousemove(function(e)
    {
        $('#image-preview')
            .css('top', (e.pageY - xOffset) + 'px')
            .css('left', (e.pageX + yOffset) + 'px');
    });
}

function uspsCycleOnMobileDevices()
{
  //on mobile, move product options block to placeholder
  if(!window.matchMedia("(max-width:768px)").matches)
    return;

    // cycle usps
    var allBoxes = $(".usp_cycle").children("div");
    var allcontainers = document.querySelectorAll(".usps_container");
    $(allcontainers).css("display", "none");
    transitionBox(null, allBoxes.first());

    function transitionBox(from, to)
    {
        function next()
        {
            var nextTo;
            if (to.is(":last-child")) {
                nextTo = to.closest(".usp_cycle").children("div").first();
            } else {
                nextTo = to.next();
            }
            to.fadeIn(500, function () {
                setTimeout(function () {
                    transitionBox(to, nextTo);
                }, 5000);
            });
        }

        if (from) {
            from.fadeOut(500, next);
        } else {
            next();
        }
    }
}

dompack.onDomReady(uspsCycleOnMobileDevices);
