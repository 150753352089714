import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import setupDatalayer from '@mod-webshop/js/analytics/gtm';
import * as gtm from '@mod-publisher/js/analytics/gtm';

import "./cookiebar.scss";

const cookiesAccepted = getWithExpiry('cookiesAccepted');

if (cookiesAccepted === true) {
    handleConsentChange({ consent: ["granted"] });
} else if (cookiesAccepted === false) {
    handleConsentChange({ consent: [] });
} else {
    startCookieRequest();
}

function startCookieRequest() {
    if (window.top !== window.self) {
        return;
    }

    let cookieinfo = whintegration.config.site.cookieinfo;
    if(!cookieinfo)
      return;

    const status = { consent: [] };
    handleConsentChange(status);

    let duration = 1000;
    let cookiemsg = document.createElement('div');
    cookiemsg.classList.add('cookiebar__notice', 'd-none');

    let animtimer = setTimeout(function () {
        document.documentElement.classList.add('webshop--showcookiebar');
        document.body.appendChild(cookiemsg);
 
        cookiemsg.innerHTML = cookieinfo;
        cookiemsg.classList.remove("d-none");

        for (let btnnode of cookiemsg.querySelectorAll("button")) {
            btnnode.addEventListener("click", ev => {

                if (btnnode.classList.contains("accept-cookie")) {
                    setWithExpiry('cookiesAccepted', true, 86400000); // 1 day in seconds
                    handleConsentChange({ consent: ["granted"] });
                } else {
                    setWithExpiry('cookiesAccepted', false, 86400000); // 1 day in seconds
                    handleConsentChange({ consent: [] });
                }

                clearTimeout(animtimer);

                document.documentElement.classList.remove('webshop--showcookiebar');
                animtimer = setTimeout(function () {
                    cookiemsg.parentNode.removeChild(cookiemsg);
                }, duration);

                location.reload();

            });
        }

    }, duration);
}

function handleConsentChange(status) 
{
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    const consentValue = status.consent.length ? status.consent.join(' ') : "denied";
    
    if (!localStorage.getItem('cookiesAccepted')) {
      gtag("consent", "default", {
        ad_storage: "granted",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "granted",
        functionality_storage: "denied",
        wait_for_update: 500
      });
    } else {
    gtag("consent", "update", {
       ad_storage: consentValue,
       ad_user_data: consentValue,
       ad_personalization: consentValue,
       analytics_storage: "granted",
       functionality_storage: consentValue
    });
    }    
}

function setWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
        value: JSON.stringify(value), 
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return JSON.parse(item.value);
}

gtm.init();
setupDatalayer();